import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import "./style.scss";
import { mq } from "../../util";
import sectionTitleBg from "@images/global/sec-title-bg.gif";
import titleNewsEn from "@images/news/title-news-en.png";
import titleNewsJa from "@images/news/title-news-ja.png";
import titleNewsKo from "@images/news/title-news-ko.png";
import titleNewsZhHans from "@images/news/title-news-zh-Hans.png";
import titleNewsZhHant from "@images/news/title-news-zh-Hant.png";

import titleIndexNewsEn from "@images/index/index-title-news-en.png";
import titleIndexNewsJa from "@images/index/index-title-news-ja.png";
import titleIndexNewsKo from "@images/index/index-title-news-ko.png";
import titleIndexNewsZhHans from "@images/index/index-title-news-zh-Hans.png";
import titleIndexNewsZhHant from "@images/index/index-title-news-zh-Hant.png";

import titleFeatureEn from "@images/index/index-title-feature-en.png";
import titleFeatureJa from "@images/index/index-title-feature-ja.png";
import titleFeatureKo from "@images/index/index-title-feature-ko.png";
import titleFeatureZhHans from "@images/index/index-title-feature-zh-Hans.png";
import titleFeatureZhHant from "@images/index/index-title-feature-zh-Hant.png";

import titleVersionsEn from "@images/index/index-title-versions-en.png";
import titleVersionsJa from "@images/index/index-title-versions-ja.png";
import titleVersionsKo from "@images/index/index-title-versions-ko.png";
import titleVersionsZhHans from "@images/index/index-title-versions-zh-Hans.png";
import titleVersionsZhHant from "@images/index/index-title-versions-zh-Hant.png";

import titleGalleryEn from "@images/gallery/title-gallery-en.png";
import titleGalleryJa from "@images/gallery/title-gallery-ja.png";
import titleGalleryKo from "@images/gallery/title-gallery-ko.png";
import titleGalleryZhHans from "@images/gallery/title-gallery-zh-Hans.png";
import titleGalleryZhHant from "@images/gallery/title-gallery-zh-Hant.png";

import titleHowToPlay from "@images/how-to-play/title-how-to-play.png";

import titleStory from "@images/story/title-story.png";

const images = {
  news: {
    en: titleNewsEn,
    ja: titleNewsJa,
    ko: titleNewsKo,
    "zh-Hans": titleNewsZhHans,
    "zh-Hant": titleNewsZhHant,
  },
  feature: {
    en: titleFeatureEn,
    ja: titleFeatureJa,
    ko: titleFeatureKo,
    "zh-Hans": titleFeatureZhHans,
    "zh-Hant": titleFeatureZhHant,
  },
  indexNews: {
    en: titleIndexNewsEn,
    ja: titleIndexNewsJa,
    ko: titleIndexNewsKo,
    "zh-Hans": titleIndexNewsZhHans,
    "zh-Hant": titleIndexNewsZhHant,
  },
  versions: {
    en: titleVersionsEn,
    ja: titleVersionsJa,
    ko: titleVersionsKo,
    "zh-Hans": titleVersionsZhHans,
    "zh-Hant": titleVersionsZhHant,
  },
  gallery: {
    en: titleGalleryEn,
    ja: titleGalleryJa,
    ko: titleGalleryKo,
    "zh-Hans": titleGalleryZhHans,
    "zh-Hant": titleGalleryZhHant,
  },
  howToPlay: titleHowToPlay,
  story: titleStory,
};

const Title = ({ page, locale }) => (
  <div className="title">
    <img className="title--frame" src={sectionTitleBg} alt="title-frame" />
    <img className="title--word" src={images[page]?.[locale]} alt="title-words" />
  </div>
);

export const DynamicTitle = ({ page, children }) => {
  const { locale } = useIntl();
  return (
    <div
      className="title"
      css={{
        position: "relative",
        display: "inline-flex",
      }}>
      <img className="title--frame" src={sectionTitleBg} alt="title-frame" />
      <img className="title--word" src={images[page]} alt="title-words" />
      <div
        css={mq({
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          top: ["11vw", null, 80],
          left: [20],
          right: 0,
          textShadow: "0 0 10px #fff, 2px 2px 7px rgba(255, 255, 255, 0.35)",
          fontSize: ["6vw", null, 50],
          letterSpacing: locale !== "en" && [6, 13],
        })}>
        {children}
      </div>
    </div>
  );
};
export default Title;
